/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 153번째 로그에서는 GitHub Copilot, Bun, 로보락 S7 MaxV Ultra, 미국 방문 이야기에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2022-06-21-github-copilot-is-generally-available-to-all-developers/"
  }, "GitHub Copilot is generally available to all developers - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sfconservancy.org/blog/2022/jun/30/give-up-github-launch/"
  }, "Give Up GitHub: The Time Has Come! - Conservancy Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/now-in-preview-amazon-codewhisperer-ml-powered-coding-companion/"
  }, "Amazon CodeWhisperer 미리 보기 출시 – 인공 지능 기반 프로그래밍 코드 생성 서비스 - Amazon Web Services 한국 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bun.sh/"
  }, "Bun is a fast all-in-one JavaScript runtime")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.roborock.com/pages/roborock-s7-maxv"
  }, "Roborock S7 MaxV 시리즈 - 더욱 편리해진 청소")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://remars.amazonevents.com/"
  }, "Amazon re:MARS 2022")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
